import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { BodySemiBold } from '@components/styled/Typography';
import withMddBadge from '@shared/ui/badges/MddBadge/withMddBadge';
const MddBadge = withMddBadge(BodySemiBold);
const InfoContainer = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '6px 0',
}));
const InfoVariable = styled.span((props) => ({
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    color: `${props.theme.palette.text.secondary}`,
    userSelect: 'none',
    cursor: 'default',
}));
const DrawdownInfoElement = ({ value = '', variable = '' }) => (_jsxs(InfoContainer, { children: [_jsx(MddBadge, { mdd: value, badgeVariant: 'tinted', badgeSize: 'small' }), _jsx(InfoVariable, { children: variable })] }));
export default DrawdownInfoElement;
